import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import styled from 'styled-components';
import Navbar from '../../components/Navbar/Navbar';
import Section from '../../components/Section/Section';
import { Helmet } from 'react-helmet';
import Panel from '../../components/Panel/Panel';
import { logIssue } from '../../helpers/logIssue';

interface Issue {
  id?: string;
  message?: string;
  lastSeenAt?: number;
  count?: number;
  details?: null | {
    [key: string]: number | string | null | boolean | undefined;
  };
}

const Wrapper = styled.div`
  background-color: #f5f8fa;
  height: 100vh;
`;

function renderDetails(details: any) {
  // if falsy, return empty string, otherwise, return a string representation with all object properties, newline separated, keys in bold
  return details
    ? Object.keys(details).map((key) => {
        return (
          <div key={key}>
            <strong>{key}:</strong> {details[key]}
          </div>
        );
      })
    : '';
}

export const Issues: React.FC = () => {
  const [issues, setIssues] = useState<Issue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [filterValue, setFilterValue] = useState('');

  const testIssue = async () => {
    await logIssue('This is a test issue', { test: 'test' });
  };

  function clearIssue(id: string) {
    console.log('clearing issue', id);
    firebase.database().ref('issues').child(id).remove();
  }

  useEffect(() => {
    const fetchIssues = () => {
      console.log('fetching issues');

      firebase
        .database()
        .ref('issues')
        .orderByChild('lastSeenAt')
        .on('value', (snapshot) => {
          const data = snapshot.val();
          if (!data) {
            setIssues([]);
            setIsLoading(false);
          } else {
            const issues = Object.values<Issue>(snapshot.val()).map((issue: Issue) => issue);
            issues.reverse();
            // const userList = data ? Object.keys(data).map((key) => ({ id: key, ...data[key] })) : [];
            // userList.sort((a, b) => (a.displayName ?? '').localeCompare(b.displayName ?? ''));
            setIssues(issues);
            setIsLoading(false);
          }
        });
    };

    fetchIssues();
  }, []);

  // const handleFilterTable = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilterValue(e.target.value);
  // };

  const filteredIssues = issues; /*.filter(
    (issue) => !filterValue || issue.displayName?.toLowerCase().includes(filterValue.toLowerCase())
  );*/

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Navbar />

      <Section>
        <Helmet title="Issues" />

        <button onClick={testIssue}>Trigger test issue</button>

        <Panel title="Issues">
          {/* <div className="bp3-input-group bp3-large mb2">
            <span className="bp3-icon bp3-icon-search" />
            <input
              onChange={handleFilterTable}
              className="bp3-input"
              type="search"
              placeholder="Search for issues..."
              dir="auto"
            />
          </div> */}

          <table className="w-100 bp3-html-table bp3-html-table-interactive bp3-html-table-striped">
            <thead>
              <tr>
                <th>Message</th>
                <th>Count</th>
                <th>Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredIssues.map((issue) => (
                <tr key={issue?.id}>
                  <td>{issue.message ?? ''}</td>
                  <td>{issue.count ?? ''}</td>
                  <td>{renderDetails(issue?.details)}</td>
                  <td>
                    <a role="button" onClick={() => clearIssue(issue.id)}>
                      Clear
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel>
      </Section>
    </Wrapper>
  );
};
