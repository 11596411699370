import firebase from 'firebase/app';

async function hashKey(input: string): Promise<string | null> {
  if (!window.crypto || !window.crypto.subtle) {
    return null;
  }
  const msgUint8 = new TextEncoder().encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

/**
 * This will log an issue to Firebase /issues. It is meant for non-technical issues
 * that typically need to be addressed by the team, like missing parameters on
 * boat models, mismatching data, etc.
 */
export async function logIssue(message: string, details: any = null) {
  console.error('Issue:', message, details);

  const hash = await hashKey(message);

  if (!hash) {
    return;
  }

  const ref = firebase.app().database().ref(`/issues/${hash}`);

  ref.transaction((current) => {
    let detailsObject = {
      id: hash,
      message: message,
      lastSeen: Date.now(),
      count: 1,
      details: details ?? {},
    };
    if (current?.count) {
      detailsObject.count += current.count;
    }
    return detailsObject;
  });
}
