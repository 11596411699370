import { NavLink } from 'react-router-dom';

const Navbar = (props: any) => (
  <nav className="bp3-navbar bp3-dark">
    <div className="bp3-navbar-group bp3-align-left">
      <div className="bp3-navbar-heading">Goldfish Sales</div>
    </div>
    <div className="bp3-navbar-group bp3-align-right">
      <NavLink
        to="/admin/issues"
        activeClassName="bp3-active"
        className="bp3-button bp3-minimal bp3-icon-search"
      >
        Tracing issues
      </NavLink>

      <NavLink
        to="/"
        activeClassName="bp3-active"
        className="bp3-button bp3-minimal bp3-icon-document"
      >
        Orders
      </NavLink>

      <span className="bp3-navbar-divider" />
      <NavLink
        to="/user/"
        activeClassName="bp3-active"
        className="bp3-button bp3-minimal bp3-icon-user"
      />
    </div>
  </nav>
);

export default Navbar;
