import * as Sentry from '@sentry/browser';
import { z } from 'zod';

import { config } from '../config';
import { logIssue } from '../helpers/logIssue';

const contentBlocksSchema = z
  .array(
    z.object({
      icon: z.optional(z.string().nullable()),
      headline: z.optional(z.string().nullable()),
      text: z.optional(z.string().nullable()), // HTML string, make sure to show raw.
    })
  )
  .nullable();

const optionsSchema = z.object({
  days: z.optional(z.union([z.number(), z.string().nullable()])), // Estimated delivery time in days

  faqs: z
    .array(
      z.object({
        title: z.string(),
        body: z.string(),
      })
    )
    .nullable()
    .optional(),

  intro: z.string().nullable().optional(),

  request_confirmed_intro: z.string().nullable().optional(), // HTML string, make sure to show raw.

  request_confirmed_list: contentBlocksSchema.optional(),
  terms: contentBlocksSchema.optional(),
  list: contentBlocksSchema.optional(), // List of content blocks shown on PaymentSuccess page

  request_information_text: z.string().nullable().optional(),
  reserve_build_slot_label: z.string().nullable().optional(),
  reserve_build_slot_text: z.string().nullable().optional(),

  /**
   * These are not in use in the frontend, but are returned by the API.
   */
  // background_image: z.optional(z.union([z.boolean(), z.string().nullable()])),
  // conversion_rate_eur: z.optional(z.union([z.number(), z.string().nullable()])),
  // conversion_rate_sek: z.optional(z.union([z.number(), z.string().nullable()])),
  // conversion_rate_usd: z.optional(z.union([z.number(), z.string().nullable()])),
  // heading: z.string().nullable().optional(),
  // onboarding: z.array(z.object({
  //   acf_fc_layout: z.string().nullable(),
  //   image: z.union([z.literal(false), z.string().nullable()]),
  //   title: z.string().nullable(),
  //   text: z.string().nullable(),
  // })).nullable().optional(),
  // key_actions: false; // These are returned, but not used in the frontend -- commented out to avoid accidental usage.
  // service_image: false; // These are returned, but not used in the frontend -- commented out to avoid accidental usage.
  // service_list_items: false; // These are returned, but not used in the frontend -- commented out to avoid accidental usage.
  // service_text: false; // These are returned, but not used in the frontend -- commented out to avoid accidental usage.
  // service_title: false; // These are returned, but not used in the frontend -- commented out to avoid accidental usage.
  // sub_heading: false; // These are returned, but not used in the frontend -- commented out to avoid accidental usage.
});

export type GlobalOptions = z.infer<typeof optionsSchema>;

export async function getGlobalOptions(): Promise<GlobalOptions> {
  const url = `${config.wordpressRootUrl}/wp-json/acf/v3/options/options`;
  const res = await fetch(url, { credentials: 'include' });
  const data = await res.json();

  const validatedData = optionsSchema.safeParse(data);

  if (!validatedData.success) {
    console.error('getGlobalOptions: API result validation failed', validatedData.error.issues);
    logIssue('getGlobalOptions: API result validation failed', {
      issues: JSON.stringify(validatedData.error.issues),
    });
    return data; // Return unvalidated data as fallback
  }

  return validatedData.data;
}
